html {
  position: relative;
  min-height: 100%;
}

body {
  margin-bottom: 24px;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 24px; /* Set the fixed height of the footer here */
}
